import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

// document.addEventListener('DOMContentLoaded', function () {
//   const currentPath = window.location.pathname;
//   document.querySelector('.c-navigation__submenu').classList.remove('active');

//   if (currentPath.includes('portfolio')) {
//     const element = document.querySelector('[data-submenu]');
//     element.querySelector('.c-navigation__submenu').classList.add('active');
//   }
// }, false);


function cookieconsent() {
	window.cookieconsent.initialise({
		"palette": {
			"popup": {
				"background": "#000"
			},
			"button": {
				"background": "#ffffff"
			}
		},
		"theme": "edgeless",
		"position": "top",
		"static": true,
		"type": "opt-out",
		"content": {
			"message": "Hier in der Hasenbande nutzen wir Cookies zur Verbesserung der dargestellten Inhalten. Weitere Informationen zu Cookies und warum wir sie nutzen findest du jederzeit auf unserer Seite",
			"dismiss": "Nein Mann",
			"allow": "Lott Jonn!",
			"deny": "Nö keine Lust",
			"link": "Mehr erfahren"
		}
	});
}

cookieconsent();

// HIGHLIGHTING OPENING DAY

function getOpeningHours() {
  var d = new Date();
  var day = new Array(7);
  day[0] = 'Sunday';
  day[1] = 'Monday';
  day[2] = 'Tuesday';
  day[3] = 'Wednesday';
  day[4] = 'Thursday';
  day[5] = 'Friday';
  day[6] = 'Saturday';

  var n = day[d.getDay()];

  $('dl').each(function() {
    var today = $(this).attr('data-day');
    if (n === today) {
      $(this).addClass('today');
    }
  });
}

getOpeningHours();